@keyframes bounce-1 {

    0%,
    to {
        transform: translateY(0)
    }

    50% {
        transform: translateY(-100px)
    }
}

@media only screen and (max-width:624px) {
    .home {
        margin-top: -25%
    }
}

.box {
    align-self: flex-end;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    margin: 0 auto;
    position: absolute;

}

.bounce-1 {
    animation-name: bounce-1;
    animation-timing-function: linear
}
.block-hero {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;


}
.block-hero-img {
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: center;
    z-index: -1;
    position: relative;
    width: 100%;
    margin-right: 80%;
    margin-top: 80px;
}
.home{
    margin-top: 150px !important;
}

.w-form {
    margin: 0 0 15px
}

.form-block-banner {
    max-width: 420px
}

.block-circle---hero-1 {
    position: absolute;
    z-index: 5;
    width: 80%
}

.block-circle---hero-2 {
    position: absolute;
    right: 20px;
    bottom: -40px;
    width: 26%
}

.image-circle---4 {
    width: 25%;
    margin-top: -8%;
    margin-left: 83%
}

.image-circle---5 {
    width: 12%;
    margin-top: -14%;
    margin-left: 97%
}

.image-circle---6 {
    width: 8%;
    margin-top: -17%;
    margin-left: 85%
}

.image-circle---7 {
    width: 14%;
    margin-top: 7%;
    margin-left: -7%
}

.image-circle---8 {
    width: 8%;
    margin-top: -2%;
    margin-left: -11%
}

.image-circle---9 {
    width: 6%;
    margin-top: -12%;
    margin-left: -1%
}

.image-circle---1 {
    width: 50%;
    margin-top: -34%;
    margin-left: -27%
}

.image-circle---2 {
        width: 30%;
    margin-top: -20%;
    margin-left: -45%
}

.image-circle---3 {
    width: 20%;
    margin-top: 9%;
    margin-left: 22%
}

.submit-button-white {
    background-color: #ff6501;
    color: #0c1d37
}
.submit-button-white:hover{
    cursor: pointer;
}

