a,
div,
h3,
i,
label,
p,
section {
    margin: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline
}

section {
    display: block;
    padding: 80px 0
}

a,
div,
h3,
i,
label,
p {
    padding: 0
}

.innerWrapper {
    padding: 0 33px;
    max-width: 1140px;
    margin: 0 auto
}

.outerWrapper {
    width: calc(100% - 90px);
    margin-left: 90px;
    overflow: hidden
}

body {
    line-height: 1;
}

.sectionContainer {
    background-color: #0c1d37;
    border-radius: 50px;
    padding: 48px;
    width: 50%;
}

:selection {
    background: #b3d4fc;
    text-shadow: none
}

.contactContainer h3 {
    letter-spacing: 1px;
    margin-bottom: 24px;
    color: #fff;
    font-size: 1.5rem
}

.contactFormContainer,
.contactTypes {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.contactTypes {
    text-align: center
}

.contactFormContainer {
    margin-top: 48px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.contactTypes .flexContainer {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
}

.contactTypes .flexContainer:first-child {
    padding: 0 15px 0 0
}

.contactTypes .flexContainer:last-child {
    padding: 0 0 0 15px
}

.contactFormContainer form {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center
}

button,
textarea {
    font-size: 100%;
    margin: 0;
    color: #222
}

button {
    vertical-align: baseline;
    line-height: normal;
    text-transform: none;
    cursor: pointer
}

.contactFormContainer button {
    padding: 14px 40px;
    font-size: .9rem;
    border: 0;
    background-color: #ff6501;
    color: #fff;
    letter-spacing: 1px;
    border-radius: 40px;
    width: 140px;
    margin: 0 auto;
    -webkit-transition: all .3s ease-in;
    transition: all .3s ease-in
}

.contactFormContainer button:hover {
    -webkit-box-shadow: 0 10px 40px 0 rgba(0, 0, 0, .2);
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, .2)
}

.contactTypes .flexContainer .typeBox {
    -webkit-box-shadow: 0 4px 8px rgb(66 93 167/56%);
    box-shadow: 0 14px 8px rgba(134, 151, 168, .1);
    -webkit-transition: all .3s ease-in;
    transition: all .3s ease-in;
    padding: 30px;
    border-radius: 15px;
    height: 100%;
    color: #82c690
}

@media (min-width:768px) {
    .contactTypes .flexContainer .typeBox:hover {
        -webkit-box-shadow: 0 14px 28px rgba(134, 151, 168, .08);
        box-shadow: 0 14px 28px rgba(134, 151, 168, .08)
    }
}

.srOnly {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    -webkit-clip-path: inset(100%);
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden
}

.contactFormContainer form input,
.contactFormContainer form textarea {
    -webkit-transition: all .3s ease-in;
    transition: all .3s ease-in;
    font-family: "Poppins", sans-serif;
    letter-spacing: 1px
}

.contactFormContainer form input {
    background-color: rgba(255, 255, 255, .1);
    border: 1px solid transparent;
    border-radius: 50px;
    color: #fff;
    font-size: 18px;
    height: 35px;
    line-height: 120%;
    margin-bottom: 20px;
    padding: 20px 26px;
    width: 300px;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    gap: 5px
}

.input1,
.input2 {
    display: grid;
    gap: 40px
}

.input2 {
    margin-left: 20px
}

.contactFormContainer form .messageInput,
.contactFormContainer form .subjectInput {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1/3
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical
}

.contactFormContainer form textarea {
    border: 1px solid #eee;
    padding: 11px 20px;
    font-size: 1rem;
    width: 100%;
    resize: none
}

.lni {
    display: inline-block;
    max-width: 30%;
    background-color: #eee;
    border-radius: 50%;
    margin-bottom: 5%
}

lni-envelope {
    height: 20px
}

.contactTypes .flexContainer .typeBox i {
    display: inline-block;
    padding: 18px;
    background: #f7f7f7;
    border-radius: 7px;
    font-size: 1.7rem;
    margin-bottom: 25px
}

.lni-envelope:before {
    content: ""
}

a {
    text-decoration: none;
    color: #fff
}

.contactTypes .flexContainer .typeBox a {
    display: block;
    margin-bottom: 15px;
    color: #ff6501;
    text-decoration: underline;
    font-size: 1rem
}

a:active,
a:hover {
    outline: 0
}

.contactTypes .flexContainer .typeBox p {
    font-size: 1.2rem;
    color: #5f6f81
}

.contactTypes .flexContainer .typeBox .lni-calendar {
    color: #1267d0;
    background-color: #e7effa
}

.lni-calendar:before {
    content: ""
}

@media only screen and (max-width:892px) {
    .contactTypes {
        display: block
    }

    .sectionContainer {
        background-color: #0c1d37;
        border-radius: 50px;
        padding: 48px;
        width: 90%
    }

    .input1,
    .input2 {
        gap: 10px
    }

    .contactFormContainer {
        margin-left: 20%;
        margin-right: 20%
    }

    .typeBox {
        justify-content: center;
        width: 250%;
        margin-left: -13%;
        align-items: center;
        margin-top: 40%
    }

    .contactFormContainer form {
        padding: 0 20px
    }

    .input2 {
        margin-left: 0
    }

    .contactTypes {
        justify-content: center;
        align-items: center;
        gap: 80px
    }
}

/* 437 to 327 minified  */