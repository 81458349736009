.post {
  width: 50vw;
  height: 32vw;
}

.img {
  width: 28vw;
  height: 20vw;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev,
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  color: #ee6700;
  font-weight: bolder;
  border-radius: 50px;
  padding: 28px;
  background-color: black;
  opacity: 0.5;
}
