.card-group {
  z-index: -1;
}

@media only screen and (max-width: 957px) {
  .card-group {
    display: flex;
    flex-direction: column;
  }
}

.content,
.section-hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  width: 100%;
  margin-top: 15%;
  max-width: 1200px;
  margin-bottom: 10%;
}

.icon-feature-bg {
  display: flex;
  margin: 38px;
  padding: 25px;
  border-radius: 24px;
  background-color: #e7e3e356;
  box-shadow: 0 25px 50px -10px rgba(100, 44, 0, 0.07);
}

.block-center,
.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.block-center {
  max-width: 80%;
  text-align: center;
}

h2 {
  font-weight: 700;
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 117%;
  color: #0c1d37;
  letter-spacing: -0.7px;
}


h5,
p {
  margin-bottom: 28px;
}

.paragraph-hero {
  max-width: 500px;
  margin-bottom: 34px;
}

.feature {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

h5 {
  font-weight: 700;
  margin-bottom: 18px;
  font-size: 22px;
  line-height: 125%;
  color: #0c1d37;
  letter-spacing: -0.3px;
}


.paragraph-feature {
  max-width: 330px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 170%;
}
