body {
  margin: 0;
  min-height: 100%;
  background-color: #fdf8f4;
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  line-height: 170%;
  align-items: stretch;
  font-weight: 400;
  /* Hide the default scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  overflow-x: hidden;
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}

/* Create a custom scrollbar */
body::-webkit-scrollbar {
  width: 0.4rem; /* Adjust width as per your preference */
}

body::-webkit-scrollbar-track {
  background: #fdf8f4; /* Track color */
}

body::-webkit-scrollbar-thumb {
  /* background: #ff9101; */
  background: #ee6700; /* Scrollbar thumb color */
}

body::-webkit-scrollbar-thumb:hover {
  background: #161e71; /* Scrollbar thumb color on hover */
}

.color-blue {
  color: #0c1d37;
}
