h1 {
  font-size: 60px;
  margin: 0.67em 0;
  font-weight: 700;
  margin-bottom: 28px;
  line-height: 122%;
  margin-top: 0px;
  color: #0c1d37;
  letter-spacing: -1px;
}

.heading-hero {
  max-width: 1100px;
}

@media only screen and (max-width: 1005px) {
  #threesteps {
    /* background-color: #12b361; */
    display: flex;
    flex-direction: column;
  }
  .img-arrow---1 {
    opacity: -1;
  }
  .img-arrow---2 {
    opacity: -1;
  }


}

/* @media only screen and (min-width: 727px) and (max-width: 1005px) {
  .paragraph-sequence {
    width: 268px;
  }
} */


p {
  margin-top: 0;
  margin-bottom: 28px;
}

.paragraph-hero {
  max-width: 500px;
  margin-bottom: 34px;
}

.text-accent {
  display: inline-block;
  padding-right: 0.25em;
  padding-left: 0.25em;
  border-radius: 8px;
  background-color: rgba(255, 101, 1, 0.12);
  color: #ff6501;
}

.grid-feature {
  width: 100%;
  margin-top: 20px;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.block-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 80%;
  /* -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column; */
  flex-direction: column;
  /* -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center; */
  justify-content: center;
  /* -webkit-box-align: center;
  -webkit-align-items: center; */
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.grid-sequence {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 30px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 0.4fr 1fr 0.4fr 1fr;
  grid-template-columns: 1fr 0.4fr 1fr 0.4fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

img {
  display: inline-block;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
}

.img-arrow---1 {
  width: 100%;
  max-width: 110px;
  /* margin-top: 15px; */
  margin-bottom: 23%;
}

.sequence {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  z-index: -1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.img-arrow---2 {
  width: 100%;
  max-width: 110px;
  /* margin-top: 40px; */
  margin-bottom: 20%;
}

h4 {
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 30px;
  line-height: 117%;
  margin-top: 0px;
  color: #0c1d37;
  letter-spacing: -0.7px;
}

.icon-sequence-bg {
  z-index: -1;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 28px;
  padding: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 25px 50px -10px rgba(100, 44, 0, 0.07);
}

.paragraph-sequence {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 170%;
}



h5 {
  font-weight: 700;
  margin-bottom: 18px;
  font-size: 22px;
  line-height: 125%;
  margin-top: 0px;
  color: #0c1d37;
  letter-spacing: -0.3px;
}

.heading-feature {
  margin-bottom: 14px;
}

.number-sequence-bg {
  position: absolute;
  left: auto;
  top: -10px;
  right: -10px;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 28px;
  height: 28px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ff6501;
}

.number-sequence {
  color: #fff;
  font-size: 16px;
  line-height: 100%;
  font-weight: 500;
}
